import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Why does my Porsche dealer hate my Corvette C8`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "778px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1ebfa092a94ab6636bc7a8f9201f4944/88632/c8-corvette-z51.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFOqIxKhNIr/8QAGRAAAgMBAAAAAAAAAAAAAAAAAhEAAxMB/9oACAEBAAEFAtWQ2EjG041NDXLCn//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/AWf/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BEX//xAAcEAACAQUBAAAAAAAAAAAAAAAAARECECEiMTL/2gAIAQEABj8C1wQxOjkRb0dZ/8QAGxABAAICAwAAAAAAAAAAAAAAAQARMUEhUcH/2gAIAQEAAT8hE67uoJchVX1GKoTk6im0wwVKPtP/2gAMAwEAAgADAAAAEEcv/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQBBYf/aAAgBAwEBPxBOR2//xAAXEQADAQAAAAAAAAAAAAAAAAAAATEh/9oACAECAQE/EFajuH//xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAhMUFRgZHR/9oACAEBAAE/EN6SAGMt37xeBl1GhNPnEwHA2ojxlKhGXLiCPY+ZxuxvLP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "c8 corvette z51",
            "title": "c8 corvette z51",
            "src": "/static/1ebfa092a94ab6636bc7a8f9201f4944/88632/c8-corvette-z51.jpg",
            "srcSet": ["/static/1ebfa092a94ab6636bc7a8f9201f4944/f93b5/c8-corvette-z51.jpg 300w", "/static/1ebfa092a94ab6636bc7a8f9201f4944/b4294/c8-corvette-z51.jpg 600w", "/static/1ebfa092a94ab6636bc7a8f9201f4944/88632/c8-corvette-z51.jpg 778w"],
            "sizes": "(max-width: 778px) 100vw, 778px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`First of all, it is not the quality of the cars, it is the perception of those who work there. In other words, they do not think like you do. When it comes to pricing, the Corvette is far more expensive than its Porsche counterpart. The reason is very simple: the car dealer does not think like you do. They allocate cars at their MSRP to neighbors, golf foursomes, and multiple car owners.`}</p>
    <p>{`When it comes to pricing, the Corvette is the best value. The base model is $57,220, and it comes with heated seats, ventilated seats, and a lot of safety equipment, such as rear cross-traffic alert and blind-spot monitoring. A front-end lift system, however, is pricey and requires an expensive extra.`}</p>
    <p>{`In addition, the Corvette has a great power-to-weight ratio. Its 495-horsepower 6.2-liter V-8 is placed behind the front seats, which improves acceleration and handling, and spices up the look. The rear end of the car will never pass the front, and the front end will be in close proximity. This is a car that demands respect at all times.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      